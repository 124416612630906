<template>
  <div class="wallet">
    <div class="waibg"></div>
    <div class="p15">
      <div
        class="bgwhite flex_column flex_items pb20 plr15"
        style="border-radius: 8px"
      >
        <p style="color: #8e8e8e" class="f14 mt50">Balance (PIX Settled)</p>
        <p class="f40 bold" style="color: #ff7900">{{ info.money }}</p>
        <div class="flex_start flex_items f12 pos mt30" style="color: #8e8e8e">
          <div class="tishi f12 f-center abs ptb5 plr10" v-if="iswhy">
            PIX under audited within 7days.
            <van-image
              fit="contain"
              :src="require('@/images/home/sanjiao.png')"
              class="abs"
              width="12"
              style="position: absolute; right: 108px; bottom: -7px"
            ></van-image>
          </div>
          <span class="mr5">Unsettled PIX</span>
          <van-image
            fit="contain"
            :src="require('@/images/home/whty.png')"
            class=""
            width="14"
            @click="iswhys"
          ></van-image>
        </div>
        <p class="mt10 bold f18 c0">{{ info.estimatedMoney }}</p>
        <div class="mt30 flex_start flex_items" style="width: 100%">
          <div
            @click="app"
            style="
              width: 49%;
              background: #ffecdb;
              border-radius: 8px;
              color: #ff7900;
            "
            class="mr10 f15 f-center ptb15"
          >
            Withdraw
          </div>
          <div
            style="
              width: 49%;
              background: #ff7900;
              border-radius: 8px;
              color: #ffffff;
            "
            @click="deposite"
            class="f15 f-center ptb15"
          >
            Deposit
          </div>
        </div>
      </div>
      <!-- 2 -->
      <a href="https://pixie.mobi/en/wallet-rules/index.html" class="mt10">
        <div
          class="flex_between flex_items bgwhite p15 mt10"
          style="border-radius: 8px"
        >
          <span class="f15 c0">Learn about the wallet rules</span>
          <van-image
            fit="contain"
            :src="require('@/images/home/myright.png')"
            class=""
            width="24"
          ></van-image>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      iswhy: true,
      info: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      this.util
        .get(this.HOST + "/userinfo/money", {
          appVersion: "1.0.6",
          account: "p36323013",
          country: "cn",
          deviceName: "iPhone12",
          language: "en",
          osVersion: "14.7.1",
          platform: 2,
          timestamp: "1631265442743",
          timezone: "Asia/Shanghai",
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            res.data.money = res.data.money.replace("PIXT", "");
            res.data.estimatedMoney = res.data.estimatedMoney.replace(
              "PIXT",
              ""
            );
            this.info = res.data;
          } else {
            Toast(res.msg);
          }
          this.isLoading = false;
        });
    },
    app() {
      this.util.app();
    },
    iswhys() {
      this.iswhy = !this.iswhy;
    },
    deposite() {
      this.$router.push({
        name: "deposit",
        params: {},
      });
    },
  },
  beforeCreate(to, from, next) {
    window.document.body.style.backgroundColor = "#f5f5f5";
  },
  beforeDestroy(to, from, next) {
    window.document.body.style.backgroundColor = "";
  },
};
</script>
<style>
.waibg {
  width: 100%;
  height: 445px;
  position: fixed;
  z-index: -1;
  background: url("../../images/home/waibg.png") no-repeat;
  background-size: cover;
}
.wallet .tishi {
  border-radius: 4px;
  background: #e8e8e8;
  color: #8e8e8e;
  top: -32px;
  width: 180px;
}
</style>